<app-navbar></app-navbar>

<section class="pt-0 pb-0" id="home">
    <div class="slider-bg">
        <ul class="slides">
            <li>
                <owl-carousel-o [options]="customOptions" class="owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStore">
                        <div class="slide-img" style="background-image: url('{{slide.image}}')">
                            <div class="hero-text-wrap">
                                <div class="hero-text white-color">
                                    <div class="container text-center">
                                        <!-- <h1 class="title raleway-font">{{slide.introtext}}</h1> -->
                                        <!-- <h2 class="white-color font-700">{{slide.title}}</h2> -->
                                        <!-- <h3 class="sub-title font-100 raleway-font">{{slide.subtitle}}</h3> -->
                                        <!-- <a [routerLink]="'/about'" [ngClass]="slide.btnSlide"><span>{{slide.btnText}} <i
                                                    class="icofont icofont-arrow-right"></i></span></a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </li>
        </ul>
    </div>
</section>

<section class="white-bg" id="features">
    <div class="container">
        <div class="row pt-4">
            <div class="col-md-6 col-sm-23 text-left sm-mt-0 d-flex align-items-center">
                <div class="d-block pl-30 sm-pl-0">
                    <div class="section-title mb-10">
                        <!-- <h2 class="raleway-font default-color mt-0">Becki is creative one page template</h2> -->
                        <h1 class="raleway-font mt-30 font-50px font-300"><span class="type-it default-color">{{
                                displayedText }}</span></h1>
                    </div>
                    <p style="text-align:justify ;">
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        At Amaravathi Mobile Stores, discover a wide range of smartphones, mobile accessories, laptop
                        accessories, car accessories, and smart gadgets. Shop top brands and premium quality products to
                        meet all your tech needs in one place.
                        Get expert support with mobile and laptop repairs, software updates, and device customization
                        services. Trust Amaravathi Mobile Stores for fast, reliable solutions to keep your gadgets
                        running smoothly.
                        We bring you products from trusted brands like Apple, Samsung, OnePlus, Dell, HP, Lenovo, JBL,
                        Bose, and Sony. Shop cutting-edge technology from leading global brands at Amaravathi Mobile
                        Stores.
                    </p>
                    <p class="mt-30 mb-0"><a class="btn btn-lg btn-gradient-secondary btn-circle">Read more</a></p>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="responsive-screen mt-0">
                    <img src="assets/images/laptop.png" class="img-responsive" alt="laptop">
                </div>
            </div>
        </div>
    </div>
</section>






<section class="grey-bg" id="team">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <!-- <h2 class="raleway-font default-color">Meet Ninjas</h2> -->
                    <h1 class="raleway-font">Accessories Available</h1>
                </div>
                <p>Enhance your devices with our extensive range of accessories, including mobile covers, screen
                    protectors, chargers, power banks, headphones, laptop bags, cooling pads, car chargers, and more.
                </p>
            </div>
        </div>

        <div class="row mt-50">
            <div class="team-slider">
                <div class="col-md-12">
                    <owl-carousel-o [options]="customOptionsTwo" class="owl-btn-center-lr">
                        <ng-template carouselSlide *ngFor="let slide of slideStoreTwo">
                            <div class="team-member">
                                <div class="team-thumb">
                                    <div class="thumb-overlay"></div>
                                    <img src="assets/1511_assets/{{slide.image}}" alt="">
                                    <div
                                        [ngClass]="slide.index === 0 ? 'member-info text-center secondary-bg' : 'member-info text-center default-bg'">
                                        <h3>{{slide.subtitle}}</h3>
                                        <!-- <span class="title">{{slide.title}}</span> -->
                                        <!-- <ul class="social-link list-inline">
                                            <li *ngFor="let social of socialLinks">
                                                <a href="#" [ngClass]="social.iconOuter"><i
                                                        [ngClass]="social.iconInner"></i></a>
                                            </li>
                                        </ul> -->
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>

    </div>
</section>




<!-- Video Popup -->


<!-- <section class="parallax-bg fixed-bg" style="background-image: url('assets/1511_assets/banner.png');background-size: contain;">
    <div class="overlay-bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center parallax-content height-400px">
                <div class="center-layout">
                    <div class="v-align-middle">
                        <h1 class="font-400 white-color raleway-font xs-font-40px">We Make Themes That Solve Problems.
                            Sometimes We Tell Stories.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<section class="white-bg" id="portfolio">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <!-- <h2 class="raleway-font default-color">What Our Client Says</h2> -->
                    <h1 class="raleway-font">Mobile Accessories</h1>
                </div>
                <p>Find premium mobile accessories like stylish covers, tempered glass, earphones, Bluetooth headsets,
                    and fast chargers. Protect and upgrade your smartphone at Amaravathi Mobile Stores.</p>
            </div>
        </div>
        <div class="row container">
            <div class="col-md-12 ">
                <owl-carousel-o [options]="customOptionsThree" class="testimonial owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStoreThree1">
                        <div class="testimonial-item">
                            <div class="testimonial-content border-radius-25">
                                <img class="img-responsive  text-center" src="{{slide.image}}" alt="" height="100px"
                                    width="100px" />
                                <h4 class="font-600 pb-30 raleway-font">{{slide.title}}</h4>
                                <!-- <span class="default-color font-14px">{{slide.subTitle}}</span> -->

                                <!-- <p class="mt-20 line-height-26 font-14px">{{slide.description}}</p> -->
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<section class="white-bg" id="pricings">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <!-- <h2 class="raleway-font default-color">What Our Client Says</h2> -->
                    <h1 class="raleway-font">Computer Accessories</h1>
                </div>
                <p>Explore top-quality computer accessories, including keyboards, mice, external hard drives, USB hubs,
                    and webcams, designed to optimize your computing experience.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ">
                <owl-carousel-o [options]="customOptionsThree" class="testimonial owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStoreThree2">
                        <div class="testimonial-item">
                            <div class="testimonial-content border-radius-25">
                                <img class="img-responsive  text-center" src="{{slide.image}}" alt="" />
                                <h4 class="font-600 pb-30 raleway-font">{{slide.title}}</h4>
                                <!-- <span class="default-color font-14px">{{slide.subTitle}}</span> -->

                                <!-- <p class="mt-20 line-height-26 font-14px">{{slide.description}}</p> -->
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<section class="white-bg" id="blog">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <!-- <h2 class="raleway-font default-color">What Our Client Says</h2> -->
                    <h1 class="raleway-font">Car Accessories</h1>
                </div>
                <p>Elevate your car journeys with our premium car accessories, such as dash cams, phone mounts,
                    Bluetooth adapters, and seat organizers.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ">
                <owl-carousel-o [options]="customOptionsThree" class="testimonial owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStoreThree3">
                        <div class="testimonial-item">
                            <div class="testimonial-content border-radius-25">
                                <img class="img-responsive  text-center" src="{{slide.image}}" alt="" />
                                <h4 class="font-600 pb-30 raleway-font">{{slide.title}}</h4>
                                <!-- <span class="default-color font-14px">{{slide.subTitle}}</span> -->

                                <!-- <p class="mt-20 line-height-26 font-14px">{{slide.description}}</p> -->
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>



<section class="white-bg transition-none" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Just Keep In Touch</h2>
                    <h1 class="raleway-font">Contact Us Now</h1>
                </div>
                <p>Looking for top tech solutions? Call or visit Amaravathi Mobile Stores today to explore the best
                    products and services for your needs.</p>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row mt-70">
            <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-50">
                <div class="row mt-20">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Postal Address</h2>
                            <p class="mt-30">
                                Upstairs of Samsung Smart Café, 86-1-5/1, Jawaharlal Nehru Road, Gandhipuram, Rajamahendravaram, Andhra Pradesh, 533101.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Office Numbers</h2>
                            <p class="mb-0 mt-30">Phone : <a href="tel:+919985726466">+91 99857 26466</a>  <br>
                                Mail : amaravathimobilestores&#64;gmail.com
                            </p>
                            <!-- <p class="mb-0">Mobile : +44 1234 567 9</p> -->
                        </div>
                    </div>
                </div>

                <div class="row mt-20">
                    <div class="col-md-12 social-icons-style-01">
                        <ul class="md-icon left-icon">
                            <li *ngFor="let social of socialMedia">
                                <a [ngClass]="social.iconClass" href="javascript:void(0)"><i
                                        [ngClass]="social.icon"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <!-- <form (submit)="sendEmail($event)" class="contact-form-style-01" #contactForm="ngForm"> -->
                <form (submit)="sending(contactForm)" class="contact-form-style-01" #contactForm="ngForm">
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="visually-hidden" for="user_name">Name</label>
                                <input type="text" minlength="2" maxlength="15"
                                    [class.is-invalid]="userName.invalid && userName.touched" name="user_name"
                                    class="md-input" id="name" placeholder="Name *" [(ngModel)]="formData.user_name"
                                    #userName="ngModel" required>
                                <div *ngIf="userName.invalid && userName.touched" class="invalid-feedback mb-10">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="mb-3">
                                <label class="visually-hidden" for="user_email">Email</label>
                                <input type="email" minlength="2" maxlength="35"
                                    [class.is-invalid]="userEmail.invalid && userEmail.touched" name="user_email"
                                    class="md-input" id="email" placeholder="Email *" [(ngModel)]="formData.user_email"
                                    #userEmail="ngModel" required email>
                                <div *ngIf="userEmail.invalid && userEmail.touched" class="invalid-feedback mb-10">
                                    A valid email is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="mb-3">
                                <label class="visually-hidden" for="message">Project Details</label>
                                <textarea name="message" [class.is-invalid]="userMessage.invalid && userMessage.touched"
                                    class="md-textarea" id="message" rows="7" placeholder="Project Details"
                                    [(ngModel)]="formData.message" #userMessage="ngModel" required></textarea>
                                <div *ngIf="userMessage.invalid && userMessage.touched" class="invalid-feedback mb-10">
                                    Project details are required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="text-left mt-20">
                                <button type="submit" name="submit"
                                    class="btn btn-outline btn-md btn-circle btn-animate remove-margin"
                                    [disabled]="contactForm.invalid" value="Send">
                                    <span>Send Message <i class="ion-android-arrow-forward"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>